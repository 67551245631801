<template>
  <div class="container row">
    <div class="col">
      <div class="main-text2 mt-180">{{ $t("ErrorPage.Error") }}</div>
      <div class="main-text2 mt-60">{{ $t("ErrorPage.Page does not exist") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPageComponent"
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

html, body {
  font-family: 'Inter';
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col {
  position: relative;
  width: 100%;
}

.main-text2 {
  font-weight: 600;
  font-size: 24px;
  color: #030303;
  display: flex;
  justify-content: center;
  text-align: center;
}

.mt-180 {
  margin-top: 180px;
}

.mt-60 {
  margin-top: 60px;
}
</style>