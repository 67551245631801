import { createRouter, createWebHistory } from 'vue-router'
import PaymentPage from '@/views/PaymentPage';

const routes = [
  {
    path: '/payment/:uuid',
    name: 'payment',
    component: PaymentPage
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
