<template>
  <div class="loader"></div>
</template>

<script>
export default {
  name: 'LoaderComponent'
}
</script>

<style scoped>
.loader {
  width: 56px;
  height: 56px;
  border: 8px solid #6772e5;
  border-right-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  top: 50%;
  margin: -28px auto 0 auto;
}

.loader:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #6772e5;
  border-radius: 50%;
  position: absolute;
  top: -1px;
  left: 33px;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
