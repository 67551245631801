import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'

const app = createApp(App)
    .use(router)
    .use(i18n)

app.config.globalProperties.$baseUrl = 'https://burunax.top'
app.config.globalProperties.$pusherApiKey = 'f64b2fc8394997bc6188'

app.mount('#app')
